@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200&display=swap');

* {
    font-family: 'Manrope';
}

.white-container {
    background-color: white;
    height: auto;
    margin: 10px 0px;
    width: auto;
    padding: 15px;
    border: 1px solid rgba(196, 196, 196, 0.5);
    border-radius: 2px;
}

.sub-heading {
    /* color: #333333; */
    color: rgba(51, 51, 51, 0.9);
    font-size: 13px;
    font-weight: 650;
    margin-bottom: 5px;
}

.heading {
    /* margin-left: 10px; */
    /* color: rgba(51, 51, 51, 0.8); */
    color: #333333;
    font-size: 14px;
    font-weight: 600;
}

input[type="checkbox"] {
    -webkit-appearance: none;
    /* Remove default styles on WebKit/Blink browsers */
    -moz-appearance: none;
    /* Remove default styles on Firefox */
    appearance: none;
    /* Remove default styles on other browsers */
}

.bodyDiv {
    display: flex;
    font-family: "Manrope", sans-serif !important;
}

.bodyMainDiv {
    margin-top: 7%;
    margin-bottom: 2%;
    margin-right: 2% !important;
    width: 80%;
    margin-left: 18%;
    background-color: #F8F8F8 !important;
}

.space-between-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.menuItemStyle {
    font-family: "Manrope" !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    color: #333333 !important;
}

.menuItemStyle:hover, .menuItemStyle:focus, .menuItemStyle:active {
    background-color: rgba(105, 0, 184, 0.1) !important;
    color: #333333 !important; 
}