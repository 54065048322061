@import url("../CSS/App.css");

.edit-camp-heading {
  font-size: 16px;
  letter-spacing: 1px;
}

.main-box {
  display: flex;
  align-items: center;
}
.edit-campaign {
  font-size: 14px;
  font-weight: 700;
  color: var(--light-purple);
  cursor: pointer;
}
/* .highlight{
    color: var(--main-purple);
    font-weight: 700;
} */
.highlight .underline {
  height: 2px;
  background: var(--main-purple);
  border-radius: 10px;
  margin-top: 2px;
}
.highlight .edit-campaign {
  color: var(--main-purple);
  font-weight: 700;
}

.campaign-box{
    background-color: var(--white-color);
    border: 1px solid var(--hover-grey);
    border-radius: 3px;
    margin-top: 10px;
    padding: 8px;
}