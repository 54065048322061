@import url("./App.css");

.table-cel,.css-1ygcj2i-MuiTableCell-root {
    font-family: 'Manrope' !important;
    font-weight: 900 !important;
    font-size: 14px !important;
    color: var(--main-purple) !important;
    padding: 10px 15px !important;
}
.css-1ex1afd-MuiTableCell-root{
    
}
.application-box{
    display: flex !important;
    align-items: center;
}
.application-box img {
    margin-right: 10px;
}
.css-1ex1afd-MuiTableCell-root{
    font-family: 'Manrope' !important;
    font-weight: 800 !important;
    font-size: 12px !important;
    /* color: var(--main-purple) !important; */
    padding: 10px 15px !important;
}
.status-div{
    display: flex;
    align-items: center;
}
.status-div img{
    margin-right: 5px;
}
.active-para{
color: var(--active-green);
}
.paused-para{
color: var(--paused-yellow);
}
.stopped-para{
color: var(--stopped-red);
}
.delete-para{
    color: var(--input-grey);
}
.pending-para{
    color: #F5900A;
}
.check-campaign .css-i4bv87-MuiSvgIcon-root{

    height: 18px !important;
    width: 18px !important;
    margin-left: 15px !important;
    

 }
