@import url("./App.css");

.calender-btn {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: var(--hover-grey);
  width: 230px;
  height: 40px;
  border-radius: 3px;
  cursor: pointer;
}
.calender-btn p {
  margin: 0;
  font-size: 13px;
  font-weight: 500;
}
.rdrDateRangePickerWrapper {
  position: absolute;
  z-index: 40;
  right: 0;
}
.rdrDefinedRangesWrapper .rdrStaticRangeSelected {
  color: var(--main-purple) !important;
}
.rdrDefinedRangesWrapper .rdrStaticRangeSelected:hover,
.rdrStaticRange:hover,
.rdrDefinedRangesWrapper .rdrStaticRangeSelected:hover {
  background-color: var(--light-purple) !important;
}
.rdrDateDisplay {
  color: var(--main-purple) !important;
}
.rdrDateDisplayWrapper {
  background-color: var(--light-purple) !important;
}
.rdrNextPrevButton {
  background-color: var(--main-purple);
}
.rdrPprevButton i {
  border-color: transparent rgb(255, 255, 255) transparent transparent;
}
.rdrNextButton i {
  border-color: transparent transparent transparent rgb(255, 255, 255);
}
.rdrSelected,
.rdrInRange,
.rdrStartEdge,
.rdrEndEdge {
  background-color: var(--main-purple) !important;
}
.rdrWeekDay {
  color: var(--main-purple);
  font-weight: 700;
}
.rdrMonthAndYearPickers select {
  width: 120px !important;
}

.button-container {
  position: absolute;
  display: flex;
  top: 21.7pc;
  
  /* width: -webkit-fill-available; */
  background: white;
  width: 36.2pc;
  z-index: 70;
  right: 0;
}
