@import url("./App.css");
/* .loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px; 

  } */

/* .rotating-image {
    width: 20px;
    animation: rotate 1s linear infinite;
  } */

/* @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  } */

.progress {
  display: flex;
  align-items: center;
  justify-content: left;
  position: relative;
  height: 10px;
  width: 300px;
  border: none;
  border-radius: 15px;
  background-color: var(--main-red);
}
.progress .color {
  position: absolute;
  background-color: var(--main-purple);
  width: 0px;
  height: 10px;
  border-radius: 15px;
  animation: progres 4s infinite linear;
}
@keyframes progres {
  0% {
    width: 0%;
  }
  25% {
    width: 50%;
  }
  50% {
    width: 75%;
  }
  75% {
    width: 85%;
  }
  100% {
    width: 100%;
  }
}
