@import url("./App.css");

.signIn-main {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.left-img-div img {
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

.right-img-div img {
  height: 80vh;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.admattic-logo {
  /* font-family: "Monument Extended Bold";
    font-weight: 900;
    color: var(--main-red);
    font-size: 40px; */
  margin-top: 25%;
}

.mid-box h2 {
  margin-top: 20%;
  margin-bottom: 0;
  font-family: "Monument Extended Bold";
  font-size: 30px;
}

.error-box {
  display: flex;
  align-items: center;
  margin-top: 5px;
  /* visibility: hidden; */
}

.error-icon,
.error-box p {
  color: var(--main-red);
  font-size: 12px;
  font-weight: 600;
  font-family: "Manrope";
}

.error-icon {
  margin: 0 5px;
}

.success-icon {
  color: var(--success-color);
  font-size: 14px;
  font-weight: 700;
  margin: 0 1.5% 0 0;
}

.success-para {
  color: var(--main-black) !important;
}

.error-para,
.success-para {
  margin: 10px 0;
}

.error-margin {
  margin: 10px auto;
}

.error-box p {
  margin: 0;
}

.email-box {
  display: flex;
  flex-direction: column;
}

.email-box label,
.pwd-box label {
  font-size: 14px;
  font-weight: 700;
  width: 100%;
}

.pwd-box {
  margin-top: 10px;
}

.email-box input,
.hide-button input {
  border: 1px solid var(--input-grey);
  height: 30px;
  border-radius: 3px;
  padding: 5px 4px 5px 10px;
  font-size: 15px;
  margin-top: 5px;
  width: 100%;
}

.hide-button {
  position: relative;
  display: flex;
}

.hide-button input {
  position: absolute;
}

.eye-button {
  position: absolute;
  right: 0;
  top: 16px;
  bottom: auto;
  background: transparent;
  border: none;
}

.eye-icon {
  color: var(--main-purple);
  height: 18px;
  width: 18px;
}

input[type="text"]:focus,
input[type="password"]:focus {
  outline: 1px solid var(--main-purple);
  /* oranges! yey */
}

.chk-box {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 104%;
}

.chk-div {
  display: flex;
}

/* .chk:checked {
    background-color: #a77e2d !important;
    color: #000000 !important;
    
  }
  .chk:after {
      box-shadow: var(--) !important;
  }
   */
.Mui-checked {
  color: var(--main-purple);
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
  color: var(--main-purple) !important;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
  padding: 0px 5px 0 0 !important;
  color: var(--main-purple) !important;
  margin: 0 0 0 -2px !important;
}

/* .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root{
 } */

.chk-div p {
  font-size: 13.5px;
  font-weight: 700;
}

.chk-box a {
  font-size: 13px;
  font-weight: 700;
  text-decoration: none;
  color: var(--main-black);
}

.signIn-btn {
  display: block;
  margin: 12% auto;
  height: 52px;
  width: 135px;
  border-style: none;
  border-radius: 3px;
  background-color: var(--main-purple);
  color: var(--white-color);
  font-size: 16px;
  font-weight: 700;
  font-family: Manrope;
  cursor: pointer;
  letter-spacing: 1px;
}

.signup-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.signup-box p,
.signup-box a {
  font-size: 12px;
  font-weight: 700;
}

.signup-box a {
  color: var(--main-red);
  text-decoration: none;
}

.back {
  color: black;
  text-decoration: none;
}

.proceed-btn {
  margin-bottom: 5%;
}

.btn-width {
  width: 90%;
}

.open-email {
  margin: 5% auto;
}

.grey-text {
  color: var(--input-grey);
  font-size: 13px;
  font-weight: 500;
}

.center-align {
  display: flex;
  justify-content: center;
  text-align: center;
}

.margin-top {
  margin-top: 5%;
}

.resend-btn {
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 1px;
  color: var(--main-purple);
}

.continue-btn {
  width: 175px;
}

.reset-text {
  margin: 8% auto;
}

.error-main {
  margin-top: 70px;
  padding-left: 15px;
}

.reset-margin {
  margin: 8% auto 0px auto !important;
}

.reset-mid {
  margin-top: 10% !important;
}

.country-box {
  display: flex;
  flex-direction: column;
}

.country-box select {
  border: 1px solid var(--input-grey);
  height: 45px;
  border-radius: 3px;
  padding: 5px 4px 5px 10px;
  font-size: 15px;
  margin-top: 5px;
  width: 104%;
}

.padding-details {
  padding: 5px 0px 5px 10px !important;
}

select {
  appearance: none;
  /* Disable default arrow */
  padding: 10px;
  border: 1px solid var(--input-grey);
  width: 200px;
  outline: none;
  /* Remove focus outline */
}

/* .hide-button {
  position: relative;
  display: flex;
} */
.hide-button select {
  position: absolute;
}

.down-icon {
  color: var(--input-grey);
  height: 18px;
  width: 18px;
  z-index: 0;
  position: absolute;
  right: 0;
  top: 19px;
}

.icons-margin {
  margin-top: 65px;
}

.otp-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 104%;
  margin-top: 5px;
}

.otp-input {
  width: 70% !important;
  margin: 0 !important;
}

.purple-btn {
  height: 41px;
  border-radius: 3px;
  background-color: var(--main-purple);
  color: var(--white-color);
  border-style: none;
  width: 24%;
  font-family: 'Manrope';
  font-weight: 700;
}

.otp-verify {
  width: 34% !important
}

.code-box {
  width: 60% !important;
}

.mandatory-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 700;
  width: 104%;
}

.mandatory-para {
  color: var(--main-red);
  font-weight: 700;
  font-size: 12px;
}

/* Style the custom dropdown arrow */
/* select::after { */
/* content: '\25BC'; Unicode down arrow character */
/* position: absolute; */
/* right: 10px; Adjust the position as needed */
/* top: 50%; Vertically center the arrow */
/* transform: translateY(-50%); */
/* pointer-events: none; Allow click events to pass through */
/* } */
.input-margins {
  margin-top: 10px;
}

.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  opacity: 0.5 !important;
}

.middle-box {
  height: 70%;
  overflow-y: scroll;
  overflow-x: hidden;
  width: auto;
}

.middle-box::-webkit-scrollbar {
  width: 10px;
  border-radius: 5px;
}

/* Track */
.middle-box::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

/* Handle */
.middle-box::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
.middle-box::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 5px;
}

.tac-btn-box {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 5%;
}

.decline-btn,
.accept-btn {
  height: 40px;
  width: 120px;
  border-style: none;
  border-radius: 3px;
  font-weight: 600;
  font-size: 14px;
  font-family: 'Manrope';
  letter-spacing: 1px;
}

.decline-btn {
  border: 2px solid var(--main-red);
  color: var(--main-red);
  background-color: var(--white-color);
}

.accept-btn {
  color: var(--white-color);
  background-color: var(--main-purple);
}

/* .modal-box::after{
  border: none;

} */
.tnc-icon {
  height: 25px;
  width: 25px;
  margin-right: 10px;
}

.modal-text {
  font-size: 25px;
  font-weight: 800;
  margin: 12px 0 14px 0;
}

.logout-box {
  border: 2px solid var(--input-grey);
  border-radius: 3px;
  display: flex;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  margin: 20px 0;
}

.terms-condition {
  text-decoration: underline;
  cursor: pointer;
}

.terms-condition:hover {
  color: #6900B8;
}

@media screen and (max-width: 768px) {
  .admattic-logo {
    /* font-family: "Monument Extended Bold";
      font-weight: 900;
      color: var(--main-red);
      font-size: 40px; */
    margin-top: 25%;
    width: 50%;
  }

  .center-div {
    display: flex;
    flex-direction: column;
    align-items: center;

  }

  .left-img-div img {
    height: 50%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }

  .right-img-div img {
    height: 50%;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
  }

  .mid-box h2 {
    margin: 10% 0%;
    text-align: center;
    font-family: "Monument Extended Bold";
    font-size: 22px;
  }

  .chk-div p {
    font-size: 12px;
    font-weight: 700;
  }


  .chk-box {
    display: flex;
    align-items: center;
    justify-content: space-between;

  }

  .chk-box a {
    font-size: 12px;

  }

  .signIn-btn {
    display: block;
    margin: 12% auto;
    height: auto;
    width: auto;
    padding: 10px 20px;
    font-size: 12px;
  }

  .details-box {
    margin: 0% 10%;
  }

}