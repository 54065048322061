@import url("./App.css");

.upper-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.back p {
  font-size: 14px;
  font-weight: 700;
}
.data-box {
  background-color: var(--white-color);
  padding: 10px;
}
.block {
  display: flex;
  align-items: flex-start;
}
.block img {
  margin: 0 12px;
}
.header {
  margin: 0;
  color: var(--main-purple);
  font-weight: 700;
  font-size: 12px;
}
.main-word {
  font-size: 14px;
  font-weight: 700;
}

.divider {
  border: 1px solid var(--hover-grey);
  width: 90%;
  position: relative;
  margin: 20px auto;
}
.new-group {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 46px;
  width: 115px;
  background-color: var(--main-purple);
  border-radius: 3px;
  border-style: none;
  cursor: pointer;
  margin: 10px 0 20px 0;
}
.new-group p {
  font-size: 12px;
  font-weight: 800;
  color: var(--white-color);
  font-family: "Manrope";
}

.input-box {
  display: flex;
  justify-content: flex-end;
}
.done-div,
.cancel-div {
  display: flex;
  align-items: center;
}

.done-div p, .cancel-div p{
    margin: 0;
    font-weight: 700;
    font-size: 10px;
    cursor: pointer
}
.done-div img, .cancel-div img{
    margin: 0px 5px 0 5px;
    height: 10px;
    cursor: pointer;
    
}
.done-div {
    color: var(--main-purple);
}
 .cancel-div {
    color: var(--main-red);
 }

.input-campaign{
  border: 1px solid var(--main-purple);
  border-radius: 3px;
  font-weight: 600;
  font-size: 13px;
  padding: 5px;
  font-family: 'Manrope';
}
.input-campaign:focus{
  border: 1.5px solid var(--main-purple);
}