.container {
    padding: 10px 0px;
    width: 100%;
    background-color: #ffffff;
    border-bottom: 3px solid rgba(0, 0, 0, 0.05);
    position: fixed;
    top: 0;
    z-index: 100;
}

.admincontainer {
    padding: 10px 0px;
    width: 100%;
    background: linear-gradient( to right, #7600CE 10%, #420075 90%);
    position: fixed;
    top: 0;
    z-index: 100;
}

.app-bar {
    display: flex;
}

.logo-div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16% !important;
}

.logo-img {
    height: 25px;
}

.main-div {
    /* padding: 0.5rem 1.4rem; */
    padding-left: 2%;
    border-left: 1.2px solid rgba(0, 0, 0, 0.5);
    margin-top: 1%;
    margin-bottom: 1%;
    margin-right: 2% !important;
    width: 80%;
        display: flex;
    align-items: center;
    justify-content: space-between;
}

.account-div {
    text-align: left;
}

.account-heading {
    color: #C4C4C4;
    font-size: 14px;
    font-weight: 600;
}

.account-content {
    display: flex;
    align-items: end;
    font-size: 17px;
    color: #333333;
    font-weight: 800;
}

.profile-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 40%;
}

.search-box {
    background-color: rgba(196, 196, 196, 0.3);
    color: #333333;
    width: 280px;
    height: 42px;
    border-radius: 2px;
}

.wallet-box {
    font-size: 14px;
    width: 260px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: #FF0047;
    color: white;
    text-decoration: none;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    border-radius: 2px;
}

.bell-button {
    border: none;
    width: 42px;
    height: 42px;
    background-color: rgba(196, 196, 196, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #333333;
    cursor: pointer;
    border-radius: 2px;
}

.bell-button-admin{
    background-color: #ffffff;
    color: #333333;
}

.plus-box {
    height: 22px;
    width: 22px;
    background-color: #ffffff;
    font-size: 20px;
    color: #FF0047;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 900;
}