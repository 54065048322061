.textfield-Box{
    border: 1.4px solid rgba(51, 51, 51, 0.3);
    border-radius: 2px;
    height: 40px;
    margin-bottom: 22px;
    margin-right: 10px;
    padding: 5px;
    width: 100%;
}

.textfield-Box-create-advertiser{
    border: 1.4px solid rgba(51, 51, 51, 0.3);
    border-radius: 2px;
    height: 40px;
    margin-bottom: 10px;
    width: 85%;
    padding-left: 5px;
    display: flex;
    align-items: center;
}

.textfield-box-enable{
    border: 1.4px solid rgba(51, 51, 51, 1);
}

.textfield-head{
    color: rgba(51, 51, 51, 0.7);
    font-weight: 700;
    font-size: 10px;
    font-family: 'Manrope';
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}