.btn-style{
  width: auto;
  height: auto;
  padding: 8px 12px;
  font-weight: 500;
  background-color: #6900B8;
  border-radius: 2px;
  border: none;
  color: white;
  font-size: 12px;
  cursor: pointer
}

.disable-btn-style{
  opacity: 0.5;
}

.download-btn-style{
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  padding: 10px;
  margin-right: 10px;
  font-weight: 650;
  background-color: inherit;
  border-radius: 2px;
  border: none;
  color: #333333;
  font-size: 13px;
  cursor: pointer
}