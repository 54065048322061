@import url("./App.css");

.top-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.center-align {
  display: flex;
  align-items: center;
  position: relative;
}

.fixed-position{
  position: fixed;
  width: 80%;
  background: #F8F8F8;
  z-index: 50;
  height: 110px;
}

.center-align h3 {
  font-size: 18px;
  margin: 0;
  font-weight: 700;
}

.input-search {
  height: 28px;
  width: 120px;
  border-radius: 4px;
  border: none;
  outline: none;
  padding-left: 10px;
}
.search-icon {
  position: absolute;
}

.campaign-heading {
  font-size: 14px;
  font-weight: 500;
  margin-right: 15px;
  color: var(--main-black);
}

.btn-dwn {
  justify-content: space-between;
  width: 83px;
  cursor: pointer;
}
.btn-dwn p,
.dwn-icon {
  font-size: 14px;
  /* font-weight: 600; */
  /* height: 14px; */
  margin: 0;
}
.campaigns-box {
  margin-top: 8px;
  /* max-height: 60vh; */
  min-height: auto;
  overflow-x: auto;
  overflow-y: auto;
}
.dropdown-menu {
  position: absolute;
  background: bisque;
  /* transition: max-height 0.3s ease-out 0s; */
  padding: 0;
  margin: 0;
  z-index: 1;
}

.dropdown-menu li {
  width: 100px;
  height: 35px;
  list-style: none;
  align-items: center;
  display: flex;
  /* justify-content: center; */

  background: var(--white-color);
  font-size: 10px;
  font-weight: 600;
  padding: 0px 0px 0px 8px;
}
.dropdown-menu a {
  font-size: 10px;
  font-weight: 600;
  color: var(--main-black);
  text-decoration: none;
}
.dropdown-button {
  border: none;
  height: 35px;
  width: 102px;
  justify-content: space-evenly !important;
  /* margin-bottom: 10px; */
  border-radius: 3px;
  background-color: transparent;
}
.dropdown-button:hover,
.dropdown-menu li:hover {
  background-color: var(--hover-grey);
}
.campaign-screen-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.campaign-profile-container {
  background: var(--white-color);
  padding: 20px;
  margin: 25px 0 0 0;
  border-radius: 8px;
  position: relative;
}
.campaign-profile-container h4 {
  margin: 0 0 10px 0;
  font-size: 15px;
  color: var(--dark-grey);
}
.title {
  display: flex;
  align-items: flex-start;
}
.img-icon {
  height: 100px;
  width: 100px;
  border-radius: 5px;
}
.title p {
  margin: 0 0 8px 0;
  font-size: 18px;
}
.info {
  margin-left: 15px;
}
.showing-status {
  margin-left: 5px;
  font-weight: 700;
}

/* /////////////////////// */

.container-details {
  /* max-width: 600px; */
  /* padding: 20px; */
  border-radius: 5px;
  /* margin: 0 auto; */
  /* background-color: #ffffff; */
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}
.error-container-box{
  display: flex;
    flex-direction: column;
}
.error-adjust{
  position: relative;
    right: auto;
    left: 44%;
    margin: 0 0 10px 0;
}
.row-details {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}
.label {
  font-weight: bold;
  width: 240px;
  font-size: 14px;
  color: var(--dark-grey);
  opacity: 0.7;
}
.value {
  flex-grow: 1;
}
.button-group {
  display: flex;
  justify-content: space-between;
}
.image-link {
  padding: 0px 10px;
  background-color: transparent;
  color: var(--main-purple);
  text-decoration: underline;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.location-icon {
  margin-right: 7px;
  height: 14px;
}
.edit-language {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 5px;
}
.edit-language-btn {
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--dark-grey);
  opacity: 0.8;
  font-weight: bold;
  width: 25px;
  cursor: pointer;
}
.edit-language-img {
  margin-right: 6px;
}
.grid-manager {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 20px;
}
.budget-main-box {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 10px;
  margin-bottom: 10px;
  /* align-items: center; */
  /* justify-content: space-between; */
}
.budget-sub-box {
  border: 1px solid var(--hover-grey);
  width: -webkit-fill-available;
  border-radius: 4px;
  padding: 6px 14px;
}
.budget-sub-box h4 {
  font-size: 11px;
  color: var(--main-purple);
}
.budget-sub-box h3 {
  margin: 0 0px 5px 0;
  font-size: 18px;
}
.performance-details {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.chart-wrapper h5 {
  font-size: 14px;
  color: var(--main-purple);
  margin: 15px 0 0 0 ;
}
.chart-wrapper h6 {
  font-size: 12px;
  margin: 15px 0px 25px 2px;
}
.decrease {
  background-color: var(--flaming-red-lighter);
  color: var(--flaming-red);
  margin-left: 5px;
}
.increase {
  background-color: var(--muted-teal-lighter);
  color: var(--muted-teal);
  margin-left: 5px;
}
.transparent-btn{
  width: auto;
    height: auto;
    padding: 14.5px 15px;
    font-weight: 600;
    background-color: #F8F8F8;
    border-radius: 2px;
    border: none;
    color: var(--main-purple);
    font-size: 12px;
    cursor: pointer;
    border: 1px solid var(--main-purple);
}
.vertical-line{
  height: 70%;
    width: 1px;
    background-color: var(--input-light-grey);
    position: absolute;
    left: 190px;
    right: auto;
}