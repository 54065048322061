.main-head{
    display: flex;
    align-items: center;
}

.profile-edit{
    display: flex;
    align-items: center;
    font-weight: 600;
    /* height: 60px; */
    margin-top: 10px;
}

.choose-file-button{
    background-color: rgba(105, 0, 184, 0.6) !important;
    color: white !important;
    font-size: 12px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    padding: 8px 10px;
    margin: 0px 10px; 
    margin-bottom: 10px;
    border-radius: 2px;
    border: none !important;
}

.choose-file-advertiser {
    background-color: rgba(105, 0, 184, 1) !important;
}

input[type=file]::file-selector-button {
    overflow: hidden;
    background-color: rgba(105, 0, 184, 0.6);
    color: white;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    padding: 8px 10px;
    margin: 0px 0px 5px 10px;
    border-radius: 2px;
    border: none;
  }

input[type="file"]{
    overflow: hidden;
}

.file-size-text{
    margin: 0px 0px 0px 10px;
    font-size: 10px;
    font-weight: 600;
    opacity: 0.5;
}

.account-details{
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

.acc-details-column{
    width: 32%;
    height: auto;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
}

.acc-detail-heading{
    font-weight: 600;
    color: #333333;
    opacity: 0.8;
    font-size: 14px;
}

.profile-box{
    height: 55px;
    width: 60px;
    border: 1.4px solid rgba(196, 196, 196, 0.5);
    color: #999999;
    padding: 10px;
    display: flex;
    border-radius: 2px;
    flex-direction: column;
    align-items: center;
    font-size: 9px;
    justify-content: flex-end;
    text-align: center;
}

.password-btn{
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(51, 51, 51, 0.2);
    border-radius: 2px;
    color: white;
    font-size: 12px;
    font-weight: 600;
    padding: 0px 20px;
    font-weight: 'Manrope';
    width: auto;
    border: none;
    cursor: pointer;
}

.password-btn-enable {
    background-color: #6900B8;
}

.field-update-btn{
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 12px;
    color: #6900B8;
    font-weight: 700;
}

.textfield{
    width: 100%;  
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.editIconButton{
    cursor: pointer;
}
