.white-box{
    background-color: white;
    width: 56%;
    height: auto;
    margin: 10px 0px;
}

.white-box-details{
    background-color: white;
    width: 38%;
    height: auto;
    margin: 10px 0px;
    padding: 20px;
}

.first-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.second-section{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.button-div {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.plus{
    color: purple;
    height: 20px;
    width: 20px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
}

.date-range-box{
    display: flex;
    align-items: center;
    color: black;
    font-size: 14px;
    font-weight: 600;
}

.datepicker{
    background-color: rgba(196, 196, 196, 0.3);
}

.chart-title{
    display: flex;
    /* justify-content: safe; */
    width: 60%;
}

.grey-border-box{
    display: flex;
    border: 1px solid rgba(196, 196, 196, 0.3);
    border-radius: 2px;
    padding: 10px;
    color: black;
    font-size: 14px;
    font-weight: 600;
    margin: 10px auto;
    width: auto;
}

.grey-border-box div{
    margin-left: 15px;
}

.arrow-style-div {
   margin-left: 4px;
}

.arrow-up-style {
    color: #6900B8;
    background-color: rgba(105, 0, 184, 0.1);
    width: 4px;
}

/* .arrow-down-style {
    color: #FF0047;
    background-color:  rgba(255, 0, 71, 0.1);
} */