:root {
  --main-red: #ff0047;
  --main-black: #000000;
  --main-purple: #6900b8;
  --input-grey: #999999;
  --white-color: #ffffff;
  --success-color: #55a47e;
  --active-green: #0b8043;
  --paused-yellow: #b07f00;
  --stopped-red: #c53929;
  --hover-grey: #ebebeb;
  --light-purple:#e1ccf1;
  --very-light-purple:#f0e6f8;
  --dark-grey:#5c5c5c;
  --input-light-grey:#C4C4C4;
  --btn-grey: #333333;

  --muted-teal:#69B6B8;
  --muted-teal-lighter:#e1f0f1;

  --flaming-red-lighter:#ffe6ed;
  --flaming-red:#FF0047;


}
