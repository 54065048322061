@import url("../CSS/App.css");

.campaign-subtype {
  margin: 0;
  font-size: 12px;
  font-weight: 700;
  color: var(--dark-grey);
}
.checkbox-app {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.para-container {
  margin-left: 10px;
}
.main-para {
  margin: 0;
  font-size: 15px;
  font-weight: 700;
}
.light-para {
  margin: 5px 0 0 0;
  font-size: 12px;
  font-weight: 500;
  color: var(--dark-grey);
  opacity: 0.8;
}

.checkbox-container {
  display: flex;
  flex-direction: column;
}
.divider-line {
  height: 1px;
  background-color: var(--dark-grey);
  opacity: 0.15;
  width: 98%;
  margin: 30px 0;
}
.purple-heading {
  color: var(--main-purple);
}
.search-icon-box {
  /* position: relative; */
  display: flex;
  align-items: center;
  padding: 5px 10px;
  width: 58%;
  border: 2px solid var(--input-light-grey);
  border-radius: 3px;
}

.search-icon {
  /* position: absolute; */
  color: var(--input-light-grey);
}
.search-input {
  width: 100%;
  outline: none !important;
  border: none;
}
.search-input::placeholder {
  color: var(--input-light-grey);
}
.search-icon-box input[type="text"]:focus {
  outline: none !important;
  border: none;
}

.campaign-name-box {
  position: relative;
  margin-top: 30px;
  margin-bottom: 20px;
  /* border: 1px solid var(--dark-grey);
  width: 70%; */
}
.campaign-name-box p {
  position: absolute;
  margin: 0;
  font-size: 11px;
  left: 5px;
  top: 5px;
}
.campaign-name-box input {
  padding: 30px 10px 5px 5px;
  width: 20%;
  border-radius: 3px;
  border: 1px solid var(--input-light-grey);
  font-family: "Manrope";
}
.campaign-name-box input:focus {
  outline: none;
  /* border:  */
}
.doneButton-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0 0 0;
}
.border-btn {
  border: 1px solid var(--main-purple);
  color: var(--main-purple);
  background-color: transparent;
  padding: 10px 24px;
  border-radius: 3px;
  font-family: "Manrope";
  font-weight: 600;
  cursor: pointer;
}
.width-adjust {
  width: auto;
  padding: 10px 34px;
}
.first-container {
  display: flex;
  align-items: center;
  margin-top: 15px;
}
.app-para p {
  margin: 0;
  font-size: 14px;
}
.app-para a {
  font-size: 14px;
  color: var(--main-purple);
}
.language-selected {
  width: auto;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-right: 10px;
}
.cancel-icon {
  margin-left: 10px;
  cursor: pointer;
  font-size: 12px;
  color: var(--main-white);
  opacity: 0.5;
}
.light-para-conversion {
  font-size: 12px;
  opacity: 0.5;
  margin: 0;
  font-weight: 600;
}
.textarea-box h3 {
  margin: 0 0 8px 0;
  font-size: 14px;
  opacity: 0.8;
}
.textarea-box textarea {
  width: 70%;
  border: 1px solid var(--input-light-grey);
  border-radius: 5px;
  font-family: "Manrope";
  padding: 10px;
}
.textarea-box textarea:focus {
  outline: none;
  border: 2px solid var(--input-light-grey);
}
.eg-container {
  display: flex;
  opacity: 0.6;
}
.eg-container h3 {
  font-size: 15px;
}
.eg-container p {
  font-size: 13px;
}
.macros-container {
  display: flex;
}
.macros-box h2 {
  font-size: 14px;
  opacity: 0.5;
  margin-top: 0;
}
.btn-container {
  display: flex;
  align-items: center;
  margin-bottom: 21px;
}
.btn-container button {
  border: none;
  height: 40px;
  width: 120px;
  background: var(--light-purple);
  color: var(--main-purple);
  border-radius: 3px;
  font-family: "Manrope";
  font-weight: 600;
}
.btn-container h5 {
  font-size: 14px;
  margin-left: 15px;
  margin-top: unset !important;
}

.add-new {
  border: 1px solid var(--hover-grey);
  padding: 25px;
  margin: 10px 0;
  width: 92%;
  border-radius: 5px;
}
.token-container {
  display: flex;

  /* justify-content: space-evenly; */
}
.label-input {
  display: flex;
  flex-direction: column;
  margin: 0 11px 24px 0;
}
.label-input label {
  font-size: 15px;
  margin-bottom: 10px;
}

.label-input input {
  width: 40vh;
  height: 40px;
  border: 2px solid var(--input-light-grey);
  border-radius: 4px;
  margin: 0 0 15px 0;
}
.delete-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 43px;
  margin: 17px 0 0 0;
}

.delete-icon {
  color: var(--main-red);
  font-size: 14px;
}
.delete-btn h4 {
  margin: -1px 0px 0 5px !important;
  font-size: 14px;
  color: var(--main-red);
  opacity: 1 !important;
}

.add-btn {
  height: 40px;
  width: 120px;
  background-color: black;
  color: white;
  border-style: none;
  border-radius: 5px;
}

.dropdown {
  background-color: white;
}

.app-lookup-dropdown {
  display: flex;
  align-items: center;
}

.app-search-button {
  background-color: var(--main-purple);
  color: white;
  padding: 6px 12px;
  border: none;
  border-radius: 3px;
}

.app-look-box {
  display: flex;
}

/* .data-source-align{
  display: flex;
  flex-direction: column;
} */
.data-div {
  box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.2);
  display: flex;
  padding: 20px 0px 20px 25px;
  margin: 30px 0 0 0;
}

.data-div h3 {
  width: 15%;
  margin-top: 5px;
  font-size: 14px;
  opacity: 0.9;
}
.data-div h5 {
  font-size: 14px;
  color: var(--dark-grey);
  opacity: 0.6;
  margin: 0 0 10px 0;
}
.heading-label {
  font-size: 14px;
  opacity: 0.9;
}
.outer-box {
  /* border-style: dashed; */
  border-radius: 5px;
  border: 2px dashed var(--input-light-grey);
  padding: 10px;
  margin: 0;
  width: fit-content;
}
.radioGrp-div a {
  text-decoration: underline;
  cursor: pointer;
  color: var(--main-purple);
}

.steps-list li {
  margin-top: 10px;
  color: var(--dark-grey);
  font-size: 14px;
  font-weight: 800;
}
.advertiser-main {
  display: flex;
  justify-content: space-between;
  background-color: var(--white-color);
  border: 1px solid var(--hover-grey);
  border-radius: 3px;
  margin-top: 10px;
  padding: 8px;
  align-items: center;
}
.advertiser-box {
  width: 60%;
}
.create-advertiser {
  padding: 20px 0;
  width: 20%;
}
.create-advertiser h6 {
  margin: 10px 0 0 0;
  width: 195px;
  opacity: 0.6;
}
.horizontal-line {
  width: 1px;
  background-color: black;
  opacity: 0.2;
  border-radius: 5px;
  height: 100px;
}
