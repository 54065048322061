@import url("./App.css");
@import url('https://fonts.googleapis.com/css2?family=Manrope&family=Raleway:wght@900&display=swap');

.chk-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.campaign-header{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.connecting-line {
  width: 150px;
  height: 1.4px;
  background: var(--dark-grey);
  /* opacity:0.5; */
}

.chk-top {
  /* margin: 0 -8px 0 -3px !important; */
}

.slider-width {
  display: flex;
  align-items: center;
  justify-content:center;
  /* justify-content: space-between; */
  width: 100%;
}
.slider-width h4 {
  font-size: 12px;
  /* opacity:0.6; */
  color: var(--dark-grey);
  margin: 10px 0 0 0;
}
.campaign-setting h5 {
  font-size: 12px;
  color: var(--dark-grey);
  margin-bottom: 0;
  margin-top: 40px;
}
.setting-para {
  font-size: 10px;
  opacity: 0.5;
  margin-top: 5px;
  margin-bottom: 25px;
}

.budget-heading {
  font-size: 13px;
  color: var(--dark-grey);
  margin-bottom: 0;
}
.budget-para {
  font-size: 11px;
  opacity: 0.5;
}
.budget-input {
  position: relative;
  display: flex;
  align-items: center;
}
.budget-input p {
  font-size: 16px;
  position: absolute;
  left: 8px;
}
.budget-input input {
  height: 35px;
  width: 80px;
  border: 1px solid var(--input-light-grey);
  border-radius: 3px;
  padding: 0 0 0 36px;
  font-family: "Manrope";
  font-size: 14px;
  font-weight: 600;
}
.small {
  -webkit-appearance: none;
  appearance: none;
}
.small::-webkit-outer-spin-button,
.small::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.budget-input input[type="number"] {
  -moz-appearance: textfield;
}
.small:focus {
  outline: none;
  border: 1px solid var(--input-light-grey);
}
.very-small-text {
  font-size: 8px;
  opacity: 0.5;
  margin: 0px 0 0 0;
}
.dpd-heading {
  font-size: 12px;
  opacity: 0.5;
  margin-bottom: 7px;
}
.check-container {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.check-container h3 {
  font-size: 12px;
  color: var(--main-purple);
}
.info-container {
  display: flex;
  align-items: flex-start;
  /* background-color: var(--light-purple); */
  background: #f0e6f8;
  width: 50%;
  padding: 10px;
  margin-top: 15px;
}
.info-icon {
  color: var(--main-purple);
  margin-right: 8px;
  font-size: 14px;
}
.info-container {
  font-size: 12px;
  font-weight: 500;
}
.info-container a {
  color: var(--main-purple);
}
.heading-review {
  font-size: 13px;
  opacity: 0.9;
}
.first-part {
  font-size: 14px;
  font-weight: 600;
  opacity: 0.6;
  margin-left: 15px;
}
.second-part {
  font-size: 12px;
  font-weight: 600;
  margin-top: 15px;
}
.ad-group-main {
  display: flex;
  align-items: center;
  margin: 15px 0 0 0;
}
.dark-text {
  font-size: 12px;
  opacity: 0.8;
}
.light-text {
  font-size: 11px;
  opacity: 0.5;
}
/* .css-mps6pm-MuiInputBase-input-MuiFilledInput-input :focus{
  outline: none !important;
} */
.hideborder :focus {
  outline: none !important;
}
/* .MuiInputBase-input:focus{
} */
.css-1ff8729-MuiInputBase-root-MuiFilledInput-root {
  background: transparent !important;
}
.css-1ff8729-MuiInputBase-root-MuiFilledInput-root:after {
  border-bottom: 2px solid var(--main-purple) !important;
}
.upper-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.box {
  display: flex;
  align-items: center;
}
.edit-adGrp {
  margin-left: 10px;
}
.camp-text {
  font-size: 12px;
  opacity: 0.7;
}
.up-icon {
  font-size: 20px;
  opacity: 0.7;
  margin: 0 0 0 5px;
}
.inside-text-input {
  position: relative;
  width: fit-content;
  margin: 0 13px 11px 0;
}
.inside-text-input p {
  position: absolute;
  margin: 0;
  font-size: 10px;
  opacity: 0.6;
  top: 5px;
  left: 8px;
  font-weight: 600;
}
.inside-text-input img {
  position: absolute;
  right: 7px;
  top: 12px;
  bottom: auto;
  height: 15px;
  cursor: pointer;
}
.inside-text-input input {
  height: 25px;
  padding: 20px 30px 0 6px;
  width: 270px;
  border: 1px solid var(--input-light-grey);
  border-radius: 3px;
  font-family: "Manrope";
}

.box-adjustment {
  display: flex;
  width: 80%;
  flex-wrap: wrap;
}
.box-divider {
  display: flex;
  align-items: flex-start;
}
.black-white-btn {
  height: 40px;
  border-radius: 3px;
  color: var(--white-color);
  background: var(--btn-grey);
  border: none;
  font-weight: 600;
  font-family: "Manrope";
  padding: 0 40px;
}
.campaign-box h4 {
  margin: 10px 0;
  opacity: 0.6;
  font-size: 14px;
}
.file-upload-container {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 10px;
}

.custom-file-input {
  cursor: pointer;
  padding: 10px 20px;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.custom-file-input:hover {
  background-color: #258cd1;
}

.selected-file {
  display: flex;
  align-items: center;
  width: 160px;
  justify-content: space-between;
  height: 41px;
  background: var(--hover-grey);
  border: 2px solid var(--input-light-grey);
  margin: 10px 20px 15px 0;
  border-radius: 4px;
  padding: 0 10px;
}

.selected-file span {
  margin-right: 0px;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.selected-file span,
.file-icon {
  font-size: 14px;
  font-weight: 600;
  opacity: 0.55;
  /* color: var(--input-light-grey); */
}

.add-box {
  height: 100px;
  width: 100px;
  display: flex;
  background: var(--hover-grey);
  border: 2px solid var(--input-light-grey);
  border-radius: 3px;
  flex-direction: column;
  align-items: flex-end;
  margin: 10px 15px 15px 0;
  justify-content: center;
  overflow: hidden;
  position: relative;
}
.add-box h5 {
  margin: 17px 0 0 0;
  color: var(--input-light-grey);
  font-size: 12px;
  width: 100px;
  /* margin-top: 17px; */
}
.images-container {
  position: relative;
  width: -webkit-fill-available;
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  align-items: center;
  height: 45vh;
  /* height: 20vh; */
}
.images-width {
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  align-items: center;
  height: 45vh;
}
.phn-layout {
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.phn-layout {
  position: absolute;
  /* width: 40vh; */
  height: -webkit-fill-available;
}
.phn-top {
  position: absolute;
  top: 12px;
  width: 190px;
  /* left: 44px; */
}
.phn-headline {
  position: absolute;
  top: 52px;
  width: 190px;
  /* left: 44px; */
}
.phn-footer {
  position: absolute;
  width: 190px;
  bottom: 0;
}
.image-show {
  position: absolute;
  height: 175px;
  width: 190px;
  overflow-y: auto ;
  overflow-x: hidden;
  background-color: #03256C;
  background-repeat: no-repeat; /* Optional: prevent repeating */
  background-position: center; /* Optional: adjust the position */
  top: 135px;
}
.img-heading{
width: 190px;
margin: 5px 0 10px 8px;
font-weight: 600;
font-size: 15px;
overflow: auto;
color: #F2F7F2;
font-family: 'Raleway', sans-serif;

}
.img-description{
  width: 190px;
  margin: 0 0 0px 8px;
  font-size: 11px;
  overflow: auto;
  color: #F2F7F2;
  font-family: 'Raleway', sans-serif;
}

.column-flex {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 15%;
}
.const-btn{
  /* height: ; */
  /* width: 64px; */
  padding: 10px 10px;
  background-color: white;
  border-style: none;
  border-radius: 4px;
  margin: 30px 0 0 8px;
  font-family: 'Raleway', sans-serif;
}

.back-Button{
  color: #6800b8;
  display: flex;
  align-items: center;
  font-weight: 700;
  cursor: pointer;
}

.app{
  display: flex;
  align-items: flex-start;
  margin: 20px 0;
}
.app h4{
  font-size: 14px;
  margin: 0;
  opacity: 0.6;
}
.image-container{
  width: 110px;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin:  0 0 10px 0;
}
#file-input {
  display: none;
}
#file-input-img{
  display: none;
}

/* #file-input-appImage {
  display: none;
} */
/* #file-input-img{
  display: none;
} */

#file-input-label {
  font-size: 14px;
  padding: 7px 10px;
  border: 1px solid var(--main-purple);
  border-radius: 4%;
  color: var(--main-purple);
}
/* .logo-img{
  width: 110px;
  height: 110px;
} */
.new-img-btn{
  font-size: 14px;
  padding: 10px 18px;
  border: none;
  border-radius: 4%;
  color: var(--white-color);
  background: var(--btn-grey);
font-weight: 600;
  font-family: "Manrope";
}