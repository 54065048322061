.div-display{
    display: flex;
    justify-content: space-between;
    align-items: center !important;
}

.filter-div{
    display: inline-block;
    margin-bottom: 10px;
}

.select-div-alignment{
    width: 100%;
    display: flex;
    align-items: last baseline;
    justify-content: space-between;
}

.select-div-size {
    width: calc(100% / 3);
}

.date-div {
    float: right;
}

.rdtr {
    border-bottom: none !important; /* Remove bottom border for each row */
  }