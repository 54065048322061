* {
    font-family: 'Manrope';
}

.qa-main-div{
    background-color: rgba(239, 227, 249, 0.5);
    padding: 20px;
    margin: 20px;
    border-radius: 3px;
}

.question-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #6900B8;
    font-weight: 600;
}

.arrow-style{
    color: #6900B8;
    cursor: pointer;
}