.coupon-stats-section {
    margin: 5px;
    display: flex;
    justify-content: space-between;
    gap: 50px;
}

.stats-box-header {
    display: flex;
    justify-content: space-between;
    gap: 30px;
}

.button-box-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 20px;
    
}

.stats-box{
    border: 1px solid rgba(196, 196, 196, 0.5);
    border-radius: 2px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    padding: 15px;
    gap: 10px;
}

.button-box{
    width: auto;
    height: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    /* padding: 0px 50px 0px 10px; */
}

.stat-status-banner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 7px;
    padding-right: 7px;
    gap: 6px;
    border-radius: 3px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;

}
.table-container {
    background-color: #ffffff;
    margin: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.modal-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
    z-index: 999;
  }
  .modal-overlay.open {
    display: block;
  }
  
  /* Modal */
  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 30px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Drop shadow */
    z-index: 1000;
  }
