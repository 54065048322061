.acc-details{
    display: flex;
    justify-content: space-between; 
}

.main-heading{
    display: flex;
    align-items: center;
}

.acc-details-content{
    border: 1px solid rgba(196, 196, 196, 0.5);
    border-radius: 2px;
    margin-top: 10px;
    margin-right: 20px;
    width: auto;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    padding: 10px 40px 10px 10px;
}

.date-range-picker{
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.datepicker{
    background-color: white;
    font-size: 14px !important;
}

.acc-subheading{
    color: #6800b8;
    font-size: 12px;
    font-weight: 700;
    display: flex;
    align-items: center;
}

.acc-subheading-content{
    color: #333333;
    font-size: 26px;
    font-weight: 600;
    margin-top: 5px;
}

/* .table-div{
    margin: 20px auto;
    background-color: white;
} */

.table-heading{
    color:#6900B8;
    font-weight: 600;
    font-size: 16px;
}

.table-td{
    color: #333333;
    font-size: 14px;
    font-weight: 600;
}