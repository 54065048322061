@import url('./App.css');
.fileContainer{
    box-shadow: none;
    flex-direction: column-reverse;
    align-items: baseline;
    padding: 0;
}
.fileContainer .uploadPicturesWrapper div{
    justify-content: unset !important;
}
.fileContainer .chooseFileButton{
    height: 40px;
  border-radius: 3px;
  color: var(--white-color);
  background: var(--btn-grey);
  border: none;
  font-weight: 600;
  font-family: 'Manrope';
  padding: 0 40px;
}
.fileContainer .deleteImage{
    top:8px;
    right: 8px;
    line-height: 15px;
    width: 15px;
    height: 15px;
    font-size: 8px;
    cursor: pointer;
    font-family: 'Manrope';
    background: var(--main-purple);
}
.fileContainer .uploadPictureContainer{
    margin: 10px 15px 10px 0px;
    padding: 0px;
    width: 100px;
    height: 100px;
}
.fileContainer .uploadPictureContainer img.uploadPicture{
    width: '100%';
    height: inherit;
}
