@import url("./App.css");

.config-campaign {
  background-color: var(--white-color);
  border: 1px solid var(--input-light-grey);
  border-radius: 5px;
  padding: 20px;
  margin: 10px 0;
}
.light-header-txt {
  margin: 0;
  font-size: 14px;
  color: var(--input-grey);
}
.config-grid-container {
  display: grid;
  grid-template-columns: 25% 50% 25%;
  margin: 10px 0 0 0;
}
.light-para-txt {
  margin: 0 0 5px 0;
  font-size: 10px;
  color: var(--input-grey);
}
.input-txt {
  height: 40px;
  width: 80%;
  border-radius: 3px;
  padding: 0 5px;
  border: 2px solid var(--input-light-grey);
}
.input-txt[type="text"]:focus {
  outline: none;
  border: 2px solid var(--main-purple);
}
.textarea {
  width: 80%;
  height: 120px;
  border: 2px solid var(--input-light-grey);
  border-radius: 3px;
  padding: 5px;
  resize: none;
}
textarea:focus {
  outline: none;
  border: 2px solid var(--main-purple);
}
.file-input-logo {
  height: 150px;
  width: 80%;
  border: 2px solid var(--input-grey);
  border-style: dashed;
  border-radius: 4px;
  background-color: #ebebeb;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.upload-logo {
  color: var(--input-grey);
  font-size: 18px;
}
.config-grid {
  display: grid;
  grid-template-columns: 49% 0% 49%;
  grid-column-gap: 10px;
}
.screen-grp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0;
}
.grp-one {
  display: flex;
}
.screen-grp h4 {
  margin: 0;
  font-size: 14px;
}
.edit-btn {
  background-color: transparent;
  border: none;
  text-decoration: underline;
  color: var(--main-purple);
  margin-left: 8px;
  font-weight: 800;
}
.edit-btn img {
  margin-right: 5px;
}

.add-grp {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  text-decoration: underline;
  color: var(--main-purple);
  margin-left: 8px;
  font-weight: 800;
}
.add-grp img {
  height: 12px;
  width: 12px;
}
.grid-box {
  display: grid;
  grid-template-columns: 49% 49%;
  grid-column-gap: 5px;
  margin: 12px 0;
}
.location-preview {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 12px 0;
}
.location-preview h5 {
  font-size: 14px;
  color: var(--main-purple);
  margin: 0;
}
.location-preview h5 span {
  font-size: 10px;
}
.purple-bg {
  background: var(--main-purple);
  color: var(--white-color);
  padding: 0px 10px;
  border-radius: 4px;
}
.purple-bg h4 {
  margin: 15px 0;
  font-size: 14px;
}

.cal-select {
  background: var(--btn-grey);
  color: var(--white-color);
  justify-content: space-evenly;
  font-size: 14px;
  border-radius: 4px;
}
.cal-select h4 {
  margin: 15px 0;
}

.note-txt {
  display: flex;
  align-items: center;
  font-size: 12px;
  opacity: 0.6;
}
.note-txt span {
  font-weight: 800;
  color: var(--flaming-red);
  text-decoration: underline;
}
.btn-container {
  display: flex;
  align-items: center;
}

.upload-container {
  border-bottom: 1.5px solid var(--input-light-grey);
  display: flex;
  align-items: center;
}

.upload-container h3 {
  margin: 0;
  padding: 10px 0;
  font-size: 14px;
  color: var(--main-purple);
  cursor: pointer;
}
.video-upload {
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.center-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.center-upload button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--very-light-purple);
  padding: 12px 15px 12px 15px;
  border: 1.5px solid var(--main-purple);
  border-style: dashed;
  border-radius: 3px;
  color: var(--main-purple);
  cursor: pointer;
  font-weight: 900;
}
.center-upload p {
  font-size: 10px;
  opacity: 0.5;
  margin-top: 20px;
}
.input-box {
  height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.video-container {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  width: 100%;
}

.font-prop {
  margin: 0;
  font-size: 12px;
}
.simple-box{
    height: 15px;
    width: 60%;
    background: var(--input-light-grey);

} 
.outer-box {
  border: 10px solid var(--input-light-grey);
  border-radius: 50px;
  padding: 25px;
}
