.stripe-box {
    background-color: #635BFF;
    width: 140px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
}

.bank-transfer {
    background-color: #6900B8;
    color: white;
    width: 140px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 700;
}

input[type=file]::file-selector-button {
    overflow: hidden;
    padding: 8px 14px;
    font-weight: 600;
    background-color: #6900B8;
    border-radius: 2px;
    border: none;
    color: white;
    font-size: 13px;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: 0;
}

input[type="file"] {
    overflow: hidden;
}

.upload-btn{
    padding: 8px 14px;
    font-weight: 600;
    background-color: #6900B8;
    border-radius: 2px;
    border: none;
    color: white !important;
    font-size: 13px !important;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: 0;
    width: 100px;
}

.list-style li{
   display: flex;
   font-size: 13px;
}

.list-item-style{
    font-weight: 800;
}

.close-btn{
    display: flex;
    align-items: center;
    color: #FF0047;
    border: none;
    background-color: transparent;
    font-weight: 800;
    cursor: pointer;
}

.check-div{
    display: flex;
}

.check-div p{
    font-size: 12px;
}

.coupon-box {
    width: 100%;
    background-color: rgba(105, 0, 184, 0.2);
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 5px;
}

.coupon-box input {
    background-color: transparent;
    border: none;
    outline: none;
    width: fit-content;
    font-size: 12px;
    width: 80%;
}

.couponButton{
    color: #6900B8;
    font-weight: 600;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.custom-address-element .p-FauxInput {
  color: #32325d;
  font-family: Arial, sans-serif;
  font-size: 16px;
}

.custom-address-element .InputElement::placeholder {
  color: #aab7c4;
}

.custom-address-element .InputElement--invalid {
  color: #fa755a;
}